import React from "react";
import { Button } from "reactstrap";
import { connectUnisatWallet, sendInscription, sendInscriptionUtils } from "./utils/utils";
import { useSelector } from "react-redux";

function App() {
  const { walletAddress } = useSelector((state: any) => state?.wallet);
  const connectWalletHandler = () => {
    connectUnisatWallet();
  };

  const deployToken = () => {
    sendInscription();
    // sendInscriptionUtils()
  };
  return (
    <div className="App">
      <h2>Water Margin</h2>
      <Button onClick={deployToken}>Mint Now</Button>
      <Button onClick={connectWalletHandler}>
        {walletAddress ? walletAddress : "Connect Wallet"}
      </Button>
    </div>
  );
}

export default App;
