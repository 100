import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface WalletState {
    walletAddress: string;
}

const initialState: WalletState = { walletAddress: "" }

const walletSlice = createSlice({
    name: 'wallet',
    initialState,
    reducers: {
        setWalletAddress(state, action: PayloadAction<string>) {
            state.walletAddress = action.payload;
        },
    },
})

export const { setWalletAddress } = walletSlice.actions
export default walletSlice.reducer